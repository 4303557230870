<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">{{ historyTitle }}</div>
      <!-- <div class="header-link">
        <router-link to="/tai-khoan/tim-ve/" v-if="userInfo.vip_data && userInfo.vip_data.is_shop"
          ><img class="mr-1" src="@/assets/img/icon_search-muted.svg" alt="" /> Tìm vé</router-link
        >
      </div> -->
    </div>
    <hr class="d-none d-lg-block" />
    <b-tabs class="transaction-tabs ticketFavoriteTab" fill lazy>
      <b-tab
        :title="tabTitle[key]"
        v-for="(filteredTicket, key) of ticketList"
        :key="'ticketList_' + key"
        @click="setTab(key)"
        :active="key == currentTab"
      >
        <div class="d-flex justify-content-end mb-2">
          <div v-if="key != 1" class="filter d-flex">
            <DropdownTimeFilter
              :data="filterTimeList"
              :isOpen="isOpenFilterTimeList"
              @updateData="updateDataFilterTimeList"
              :itemActived="filterTimeListActived"
              @updateModal="updateModalFilterTimeList"
              :isLoading="isLoading || loading"
              class="mr-md-2"
              :class="{
                'w-100': giftType !== 'GIFTED'
              }"
            />
          </div>
        </div>
        <Spinner v-if="(totalPages[key] == 0 || ticketList[key].length == 0) && (isLoading || loading)" class="py-3" />
        <div class="content-list" v-else-if="totalPages[key] > 0">
          <div>
            <div
              class="content-list__item"
              :class="{
                'mb-2': giftType === 'OWN'
              }"
              v-for="ticket in filteredTicket"
              :key="'ticket_' + key + '_' + ticket.id + '_' + ticket.period_no"
              @click="getTransactionDetail(ticket)"
            >
              <div v-if="!loading || isMobile">
                <template>
                  <div class="content-block order-block" style="padding-top: 11px; padding-bottom: 11px">
                    <div class="content-image">
                      <img :src="ticket.game_img" alt />
                    </div>
                    <div class="content-detail">
                      <template>
                        <div class="order-detail">
                          <div class="order-info">
                            <span>VSKT {{ ticket.product_name }} - </span>
                            <span v-if="ticketInfo(ticket).is_group">x</span>
                            <span>
                              <strong>
                                {{ ticketInfo(ticket).is_group ? `${groupTicketNumber}` : ticketInfo(ticket).value }}
                              </strong>
                            </span>
                            <br />
                            <i>
                              {{ getOnlyDate(getFulltime(ticket.lottery_time), "dddd ngày DD/MM/YYYY") }}
                              <template v-if="ticketInfo(ticket).is_group">
                                <span>(Cặp nguyên <strong>110</strong> vé)</span>
                              </template>
                              <template v-else>
                                <span
                                  >(<strong>{{ ticketInfo(ticket).quantity }}</strong> vé)</span
                                >
                              </template>
                            </i>
                            <br />
                            <span>
                              <img src="@/assets/img/icon/icon_ticket.svg" alt="" style="margin-right: 5px" />
                              {{ ticket.shipping_method }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <div class="ticket-detail">
                        <div class="ticket-price">{{ formatPrice(ticket.total_price) }}</div>
                        <div class="ticket-status" v-if="(ticket.status == 0 || ticket.status == 99) && key == 1">
                          <span class="status-icon">
                            <img src="@/assets/img/icon/icon-ve_choxacnhan.svg" alt />
                          </span>
                          <span class="status-text">CHỜ XÁC NHẬN</span>
                        </div>
                        <div class="ticket-status" v-else-if="ticket.status == 1 && key == 1">
                          <span class="status-icon">
                            <img src="@/assets/img/icon/icon-ve_dain.svg" alt />
                          </span>
                          <span class="status-text">{{
                            isTraditional(ticket.game_id) ? "CHỜ QUAY SỐ" : "VÉ ĐÃ IN"
                          }}</span>
                        </div>
                        <div
                          class="ticket-status"
                          v-else-if="(ticket.reward_status == true && key == 2) || ticket.transfer_status === 1"
                        >
                          <span class="status-icon">
                            <img src="@/assets/img/icon/icon-ve_trungthuong.svg" alt />
                          </span>
                          <span class="status-text primary-status reward"
                            >TRÚNG THƯỞNG<span class="d-none d-md-inline">:</span>
                            <span class="font-weight-bold">
                              {{ formatPrice(ticket.total_reward_amount, "Đ") }}</span
                            ></span
                          >
                        </div>
                        <div class="ticket-status" v-else-if="ticket.reward_status == false && key == 2">
                          <span class="status-icon">
                            <img src="@/assets/img/icon/icon-ve_dain.svg" alt />
                          </span>
                          <span class="status-text">ĐÃ XỔ</span>
                        </div>
                        <div class="ticket-status" v-else-if="key == 3">
                          <span class="status-icon">
                            <img src="@/assets/img/icon/icon-ve_dahuy.svg" alt />
                          </span>
                          <span class="status-text">ĐÃ HUỶ</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="containerMessage" v-if="giftType !== 'OWN'">
                  <hr />

                  <div v-if="giftType === 'GIFT'" class="row">
                    <div class="col-12 col-md-6 message">
                      <span class="status-icon">
                        <img src="@/assets/img/icon/ticket_icon_gift.svg" alt="gift" />
                      </span>
                      <div>
                        Bạn đã tặng vé cho <strong>{{ ticket.invite_code }}</strong
                        ><span v-if="ticket.gift_message">: "{{ ticket.gift_message }}"</span>
                      </div>
                    </div>
                    <div
                      v-if="ticket.reward_reply_message"
                      class="col-12 col-md-6 justify-content-md-end message d-none d-md-inline-flex"
                    >
                      <span class="status-icon d-md-none">
                        <img src="@/assets/img/icon/ticket_icon_message_win.svg" alt="message_win" />
                      </span>
                      <div>"{{ ticket.reward_reply_message }}"</div>
                    </div>
                    <div v-if="ticket.buy_reply_message" class="col-12 message">
                      <span class="status-icon">
                        <img src="@/assets/img/icon/ticket_icon_message.svg" alt="message" />
                      </span>

                      <div>"{{ ticket.buy_reply_message }}"</div>
                    </div>
                    <div
                      v-if="ticket.reward_reply_message"
                      class="col-12 col-md-6 justify-content-md-end message d-md-none"
                    >
                      <span class="status-icon d-md-none">
                        <img src="@/assets/img/icon/ticket_icon_message_win.svg" alt="message_win" />
                      </span>
                      <div>
                        <strong>{{ ticket.invite_code }}</strong> đã cảm ơn: "{{ ticket.reward_reply_message }}"
                      </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-12 col-md-6 message">
                      <span class="status-icon">
                        <img src="@/assets/img/icon/ticket_icon_gift.svg" alt="gift" />
                      </span>
                      <div>
                        <strong>{{ ticket.name || ticket.username }}</strong> đã tặng vé<span v-if="ticket.gift_message"
                          >: "{{ ticket.gift_message }}"</span
                        >
                      </div>
                    </div>
                    <div
                      v-if="ticket.reward_reply_message"
                      class="col-12 col-md-6 justify-content-md-end message d-none d-md-inline-flex"
                    >
                      <span class="status-icon d-md-none">
                        <img src="@/assets/img/icon/ticket_icon_message_win.svg" alt="message_win" />
                      </span>
                      <div>"{{ ticket.reward_reply_message }}"</div>
                    </div>
                    <div v-if="ticket.buy_reply_message" class="col-12 message">
                      <span class="status-icon">
                        <img src="@/assets/img/icon/ticket_icon_message.svg" alt="message" />
                      </span>

                      <div>"{{ ticket.buy_reply_message }}"</div>
                    </div>
                    <div
                      v-if="ticket.reward_reply_message"
                      class="col-12 col-md-6 justify-content-md-end message d-md-none"
                    >
                      <span class="status-icon d-md-none">
                        <img src="@/assets/img/icon/ticket_icon_message_win.svg" alt="message_win" />
                      </span>
                      <div>"{{ ticket.reward_reply_message }}"</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else style="height: 85px">
                <content-placeholders class="h-100">
                  <content-placeholders-img class="h-100" />
                </content-placeholders>
              </div>
            </div>
            <!-- <Spinner v-if="loading" class="d-none d-lg-block" /> -->
            <Spinner v-if="!isLoadEnd[currentTab]" class="d-block d-lg-none py-3" />
          </div>
          <a class="d-none d-lg-inline-block" @click="scrollBehavior()" v-if="!isMobile">
            <b-pagination
              v-if="totalPages[key] > perPage && ticketList[key] && ticketList[key].length"
              v-model="pagination[key].currentPage"
              pills
              hide-ellipsis
              first-number
              last-number
              hide-goto-end-buttons
              :per-page="perPage"
              :total-rows="totalPages[key]"
              @input="getTransactionHistoryPaging(parseInt(key))"
              next-class="d-none"
              prev-class="d-none"
            ></b-pagination>
          </a>
        </div>
        <div v-else class="ticketFavoriteTabNone">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
              <g transform="translate(-798 -398)">
                <rect width="48" height="48" transform="translate(798 398)" fill="#fff" opacity="0" />
                <g transform="translate(49 -40)">
                  <rect width="48" height="48" transform="translate(749 438)" fill="#666" opacity="0" />
                  <path
                    d="M24.7-35.575a20.906,20.906,0,0,1,20.9,20.9,20.9,20.9,0,0,1-20.9,20.9,20.9,20.9,0,0,1-20.9-20.9A20.9,20.9,0,0,1,24.7-35.575Zm0-3.1a24,24,0,0,0-24,24,24,24,0,0,0,24,24,24,24,0,0,0,24-24A24,24,0,0,0,24.7-38.672ZM21.219-5.381A1.161,1.161,0,0,0,20.058-4.22v.774a1.161,1.161,0,0,0,1.161,1.161h6.968a1.161,1.161,0,0,0,1.161-1.161V-4.22a1.161,1.161,0,0,0-1.161-1.161H27.026V-18.93a1.161,1.161,0,0,0-1.161-1.161H21.219a1.161,1.161,0,0,0-1.161,1.161v.774a1.161,1.161,0,0,0,1.161,1.161h1.161V-5.381ZM24.7-28.607a3.1,3.1,0,0,0-3.1,3.1,3.1,3.1,0,0,0,3.1,3.1,3.1,3.1,0,0,0,3.1-3.1A3.1,3.1,0,0,0,24.7-28.607Z"
                    transform="translate(748.297 476.672)"
                    fill="#666"
                  />
                </g>
              </g>
            </svg>
            <p>Không có vé nào</p>
          </div>
          <a class="btn btnConfirm text-uppercase pointer my-0" @click="$router.push('/')">Mua vé</a>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import OrderService from "@/api/order";
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import DropdownTimeFilter from "../MaxGame/DropdownTimeFilter";
import _ from "lodash";
import DateUtils from "../../utils/date";

const ORDER_WAITING = 1;
const ORDER_BOUGHT = 2;
const ORDER_CANCELED = 3;
export default {
  layouts: "DepositHistory",
  components: {
    Spinner,
    DropdownTimeFilter
  },
  data() {
    return {
      timeOut: null,
      resizeTimeout: null,
      isLoading: true,
      loading: true,
      perPage: 10,
      tabTitle: {
        1: "CHƯA XỔ",
        2: "ĐÃ XỔ",
        3: "ĐÃ HUỶ"
      },
      totalPages: {
        1: [],
        2: [],
        3: []
      },
      depositList: [],
      ticketList: {
        1: [],
        2: [],
        3: []
      },
      mobileFlag: {
        1: false,
        2: false,
        3: false
      },
      isLoadEnd: {
        1: false,
        2: false,
        3: false
      },
      pagination: {
        1: { currentPage: 1, pageSize: 10 },
        2: { currentPage: 1, pageSize: 10 },
        3: { currentPage: 1, pageSize: 10 }
      },
      saveWidth: null,
      filterTimeList: [
        {
          key: "all",
          value: "Tất cả vé",
          isActive: true
        },
        {
          key: 7,
          value: "7 ngày",
          isActive: true
        },
        {
          key: 30,
          value: "30 ngày",
          isActive: true
        },
        {
          key: 60,
          value: "60 ngày",
          isActive: true
        },
        {
          key: 90,
          value: "90 ngày",
          isActive: true
        }
      ],
      isOpenFilterTimeList: false,
      filterTimeListActived: {
        key: 7,
        value: "7 ngày",
        isActive: true
      },
      isOpenFilterList: false,
      filterList: [],
      filterListActived: {},
      saveTimeOut: null,
      isOpenFilterListGiftTicket: false,
      filterListGiftTicket: [
        {
          key: "all",
          value: "Tất cả vé tặng",
          isActive: true
        },
        {
          key: "buy",
          value: "Đã cảm ơn vé được tặng",
          isActive: true
        },
        {
          key: "no_buy",
          value: "Chưa cảm ơn vé được tặng",
          isActive: true
        },
        {
          key: "reward",
          value: "Đã cảm ơn vé trúng",
          isActive: true
        },
        {
          key: "no_reward",
          value: "Chưa cảm ơn vé trúng",
          isActive: true
        }
      ],
      filterListActivedGiftTicket: { key: "all", value: "Tất cả vé tặng", isActive: true }
    };
  },
  computed: {
    historyTitle() {
      const routeName = this.$route.meta.name;
      return routeName.charAt(0).toUpperCase() + routeName.slice(1);
    },
    giftType() {
      return this.$route.meta.gift_type;
    },
    currentTab: function() {
      return this.$store.getters.getterOrderTab;
    },
    listGame() {
      return this.$store.getters.getterListGame;
    },
    isMobile() {
      return window.innerWidth <= this.getMobileThresshold();
    },
    userInfo() {
      return this.$store.getters.getterUserInfo;
    },
    isBuyTogether() {
      return this.$route.name === "ve-da-mua-chung";
    },
    getTransactionHistoryRequest() {
      return this.isBuyTogether ? OrderService.getTransactionHistoryBuyGroup : OrderService.getTransactionHistory;
    }
    // filterList() {
    //   return Object.values(this.$store.getters.getterListGame).map((item, index) => {
    //     const filt = {
    //       key: ticket.id,
    //       value: ticket.name,
    //       isActive: true
    //     };
    //     if (index === 0) {
    //       // this.filterListActived = filt;
    //     }
    //     return filt;
    //   });
    // }
  },
  created() {
    window.onresize = this.handleResize;
    this.saveWidth = window.innerWidth;
    const isMobile = window.innerWidth <= this.getMobileThresshold();
    const paginationData = this.$store.getters.getterSavePagination;
    const filterListActivedData = this.$store.getters.getterSaveFilterListActived;
    const filterListActivedGiftTicketData = this.$store.getters.getterSaveFilterListActivedGiftTicket;

    if (paginationData && !isMobile) {
      this.pagination = { ...paginationData };
    }

    if (filterListActivedData) {
      this.filterListActived = { ...filterListActivedData };
    }

    if (filterListActivedGiftTicketData) {
      this.filterListActivedGiftTicket = { ...filterListActivedGiftTicketData };
    }
    this.$store.commit("setSavePagination", null);
    this.$store.commit("setSaveFilterListActived", null);
    this.$store.commit("setSaveFilterListActivedGiftTicket", null);

    window.addEventListener("scroll", this.handlerScroll);
  },
  mounted() {
    const isMobile = window.innerWidth <= this.getMobileThresshold();
    if (isMobile) {
      this.getTransactionHistory(this.currentTab);
    } else {
      this.getTransactionHistoryPaging(this.currentTab);
    }

    if (this.listGame) {
      if (this.filterListActived?.key) {
        this.addFilterData(true);
      } else {
        this.addFilterData();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handlerScroll);
  },
  methods: {
    handlerScroll() {
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();
      if (bottomOfWindow) {
        this.loadMore();
      }
    },
    handleResize() {
      if (this.saveWidth === window.innerWidth) {
        return;
      } else {
        this.saveWidth = window.innerWidth;
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (this.mobileFlag[this.currentTab] === isMobile) {
        return;
      }
      this.mobileFlag[this.currentTab] = isMobile;

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.pagination[this.currentTab] = { currentPage: 1, pageSize: 10 };
        this.isLoadEnd[this.currentTab] = false;
        this.ticketList[this.currentTab] = [];
        if (isMobile) {
          this.getTransactionHistory(this.currentTab);
        } else {
          this.getTransactionHistoryPaging(this.currentTab);
        }
      }, 1000);
    },
    loadMore() {
      if (this.isLoadEnd[this.currentTab] || this.isLoading) return;

      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(() => {
        this.loading = true;
        this.getTransactionHistory(this.currentTab);
      }, 200);
    },
    isKeno(game_id) {
      return game_id === this.$store.getters.getterGameId["keno"];
    },
    isTraditional(game_id) {
      return game_id === this.$store.getters.getterGameId["xsmn"];
    },
    setTab(val) {
      this.$store.commit("setOrderTab", val);
    },
    scrollBehavior() {
      document.getElementById("scroll").scrollIntoView({
        behavior: "smooth"
      });
    },
    getTransactionHistory(status = null) {
      // if (this.isLoadEnd[this.currentTab]) {
      //   this.loading = false;
      //   this.isLoading = false;
      //   return;
      // }
      this.isLoading = true;

      if (status) {
        if (this.saveTimeOut) {
          clearTimeout(this.saveTimeOut);
        }
        let whereFrom = "";
        if (this.filterTimeListActived !== "all") {
          whereFrom = DateUtils.formatDate(
            new Date(new Date().setTime(new Date().getTime() - this.filterTimeListActived.key * 24 * 60 * 60 * 1000))
          );
        }
        this.saveTimeOut = setTimeout(() => {
          this.getTransactionHistoryRequest(
            status,
            this.pagination[this.currentTab].currentPage || 1,
            this.perPage,
            this.filterTimeListActived.key === "all" ? null : whereFrom,
            null,
            this.filterListActived.key === "all" ? null : this.filterListActived.key,
            "",
            this.giftType,
            this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
          )
            .then((res) => {
              if (res.data.status) {
                const { orderList, orderCount } = res.data.data;

                if (
                  orderList?.length +
                    this.pagination[this.currentTab].pageSize *
                      ((this.pagination[this.currentTab].currentPage || 1) - 1) >=
                    orderCount ||
                  orderList?.length === orderCount
                ) {
                  this.isLoadEnd[this.currentTab] = true;
                } else {
                  this.isLoadEnd[this.currentTab] = false;
                }
                if (
                  orderList.length > 0 &&
                  this.ticketList[status].findIndex((item) => item.id === orderList[0].id) < 0
                )
                  this.ticketList[status] = this.ticketList[status].concat(orderList);
                this.pagination[this.currentTab] = {
                  ...this.pagination[this.currentTab],
                  currentPage: (this.pagination[this.currentTab].currentPage || 1) + 1
                };
                this.totalPages[status] = orderCount;
              }
            })
            .catch(() => {
              this.totalPages[status] = 0;
            })
            .finally(() => {
              this.loading = false;
              this.isLoading = false;
            });
        }, 500);
      } else {
        let whereFrom = "";
        if (this.filterTimeListActived !== "all") {
          whereFrom = DateUtils.formatDate(
            new Date(new Date().setTime(new Date().getTime() - this.filterTimeListActived.key * 24 * 60 * 60 * 1000))
          );
        }
        let orderWaiting = this.getTransactionHistoryRequest(
          ORDER_WAITING,
          1,
          this.perPage,
          null,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        );
        let orderBought = this.getTransactionHistoryRequest(
          ORDER_BOUGHT,
          1,
          this.perPage,
          this.filterTimeListActived.key === "all" ? null : whereFrom,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        );
        let orderCanceled = this.getTransactionHistoryRequest(
          ORDER_CANCELED,
          1,
          this.perPage,
          null,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        );
        axios
          .all([orderWaiting, orderBought, orderCanceled])
          .then(
            axios.spread((...responses) => {
              this.totalPages = {
                1: responses[0].data.data.orderCount,
                2: responses[1].data.data.orderCount,
                3: responses[2].data.data.orderCount
              };
              this.ticketList = {
                1: responses[0].data.data.orderList,
                2: responses[1].data.data.orderList,
                3: responses[2].data.data.orderList
              };
              // this.isLoadEnd[this.currentTab] = true;
              this.isLoadEnd = {
                1: responses[0].data.data.orderCount === responses[0].data.data.orderList.length,
                2: responses[1].data.data.orderCount === responses[1].data.data.orderList.length,
                3: responses[2].data.data.orderCount === responses[2].data.data.orderList.length
              };

              this.pagination = {
                1: { currentPage: 2, pageSize: 10 },
                2: { currentPage: 2, pageSize: 10 },
                3: { currentPage: 2, pageSize: 10 }
              };
            })
          )
          .catch(() => {
            this.pagination = {
              1: { currentPage: 1, pageSize: 10 },
              2: { currentPage: 1, pageSize: 10 },
              3: { currentPage: 1, pageSize: 10 }
            };
            this.totalPages = {
              0: [],
              1: [],
              2: []
            };
            this.ticketList = {
              0: [],
              1: [],
              2: []
            };
          })
          .finally(() => {
            this.loading = false;
            this.isLoading = false;
          });
      }
    },
    getTransactionHistoryPaging(status = null) {
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (isMobile) {
        return;
      }
      this.loading = true;

      if (status) {
        let whereFrom = "";
        if (this.filterTimeListActived !== "all") {
          whereFrom = DateUtils.formatDate(
            new Date(new Date().setTime(new Date().getTime() - this.filterTimeListActived.key * 24 * 60 * 60 * 1000))
          );
        }
        this.getTransactionHistoryRequest(
          status,
          this.pagination[status].currentPage,
          this.perPage,
          this.filterTimeListActived.key === "all" ? null : whereFrom,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        )
          .then((res) => {
            if (res.data.status) {
              this.ticketList[status] = res.data.data.orderList;
              this.totalPages[status] = res.data.data.orderCount;
            }
          })
          .catch(() => {
            this.totalPages[status] = 0;
          })
          .finally(() => {
            this.loading = false;
            this.isLoading = false;
          });
      } else {
        let whereFrom = "";
        if (this.filterTimeListActived !== "all") {
          whereFrom = DateUtils.formatDate(
            new Date(new Date().setTime(new Date().getTime() - this.filterTimeListActived.key * 24 * 60 * 60 * 1000))
          );
        }
        let orderWaiting = this.getTransactionHistoryRequest(
          ORDER_WAITING,
          this.pagination[ORDER_WAITING].currentPage,
          this.perPage,
          null,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        );
        let orderBought = this.getTransactionHistoryRequest(
          ORDER_BOUGHT,
          this.pagination[ORDER_BOUGHT].currentPage,
          this.perPage,
          this.filterTimeListActived.key === "all" ? null : whereFrom,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        );
        let orderCanceled = this.getTransactionHistoryRequest(
          ORDER_CANCELED,
          this.pagination[ORDER_CANCELED].currentPage,
          this.perPage,
          null,
          null,
          this.filterListActived.key === "all" ? null : this.filterListActived.key,
          "",
          this.giftType,
          this.filterListActivedGiftTicket.key === "all" ? null : this.filterListActivedGiftTicket.key
        );

        axios
          .all([orderWaiting, orderBought, orderCanceled])
          .then(
            axios.spread((...responses) => {
              this.totalPages = {
                1: responses[0].data.data.orderCount,
                2: responses[1].data.data.orderCount,
                3: responses[2].data.data.orderCount
              };
              this.ticketList = {
                1: responses[0].data.data.orderList,
                2: responses[1].data.data.orderList,
                3: responses[2].data.data.orderList
              };
            })
          )
          .catch(() => {
            this.pagination = {
              1: { currentPage: 1, pageSize: 10 },
              2: { currentPage: 1, pageSize: 10 },
              3: { currentPage: 1, pageSize: 10 }
            };
            this.totalPages = {
              0: [],
              1: [],
              2: []
            };
            this.ticketList = {
              0: [],
              1: [],
              2: []
            };
          })
          .finally(() => {
            this.loading = false;
            // this.isLoading = false;
          });
      }
    },

    getTransactionDetail(
      ticket
      // display_order_id
    ) {
      const ticketId = this.isBuyTogether ? ticket.id : ticket.display_ticket_id;

      let path = this.$route.path;

      if (path.charAt(path.length - 1) === "/") {
        this.$router.push(`${this.$route.path}${ticketId}`);
      } else {
        this.$router.push(`${this.$route.path}/${ticketId}`);
      }
    },
    updateModalFilterList(value) {
      this.isOpenFilterList = value;
    },
    updateModalFilterListGiftTicket(value) {
      this.isOpenFilterListGiftTicket = value;
    },
    updateDataFilterList(newObj) {
      this.filterListActived = newObj;
    },
    updateDataFilterListGiftTicket(newObj) {
      this.filterListActivedGiftTicket = newObj;
    },
    updateModalFilterTimeList(value) {
      this.isOpenFilterTimeList = value;
    },
    updateDataFilterTimeList(newObj) {
      this.filterTimeListActived = newObj;
    },
    addFilterData(isHaveFilter) {
      if (!isHaveFilter) {
        this.filterListActived = {
          key: "all",
          value: "Tất cả vé",
          isActive: true
        };
      }

      const listBuyGroupFilter = ["power", "mega"];

      let listGameData = Object.values(this.listGame).filter((item) => {
        if (this.isBuyTogether) {
          return listBuyGroupFilter.includes(item.handler);
        }

        return true;
      });

      this.filterList = [
        {
          key: "all",
          value: "Tất cả vé",
          isActive: true
        },
        ...listGameData.map((item) => ({
          key: item.id,
          value: item.name,
          isActive: true
        }))
      ];
    },
    ticketInfo(ticket) {
      try {
        return JSON.parse(ticket.ticket_info);
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    handleChangeTab(key) {
      this.ticketList[key] = [];
      this.getTransactionHistoryPaging(parseInt(key));
    }
  },
  watch: {
    listGame: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.addFilterData();
        }
      }
    },
    filterListActived: {
      deep: true,
      handler(newVal, oldVal) {
        const isMobile = window.innerWidth <= this.getMobileThresshold();
        if (!_.isEmpty(oldVal) && newVal.key && newVal.key !== oldVal.key) {
          this.isLoadEnd[this.currentTab] = false;
          this.ticketList[this.currentTab] = [];
          if (isMobile) {
            this.pagination[this.currentTab] = { currentPage: 0, pageSize: 10 };
            this.getTransactionHistory(this.currentTab);
          } else {
            this.pagination[this.currentTab] = { currentPage: 1, pageSize: 10 };
            this.getTransactionHistoryPaging(this.currentTab);
          }
        }
      }
    },
    filterTimeListActived: {
      deep: true,
      handler(newVal, oldVal) {
        const isMobile = window.innerWidth <= this.getMobileThresshold();
        if (!_.isEmpty(oldVal) && newVal.key && newVal.key !== oldVal.key) {
          this.isLoadEnd[this.currentTab] = false;
          this.ticketList[this.currentTab] = [];
          if (isMobile) {
            this.pagination[this.currentTab] = { currentPage: 0, pageSize: 10 };
            this.getTransactionHistory(this.currentTab);
          } else {
            this.pagination[this.currentTab] = { currentPage: 1, pageSize: 10 };
            this.getTransactionHistoryPaging(this.currentTab);
          }
        }
      }
    },
    filterListActivedGiftTicket: {
      deep: true,
      handler(newVal, oldVal) {
        const isMobile = window.innerWidth <= this.getMobileThresshold();
        if (!_.isEmpty(oldVal) && newVal.key && newVal.key !== oldVal.key) {
          this.isLoadEnd[this.currentTab] = false;
          this.ticketList[this.currentTab] = [];
          if (isMobile) {
            this.pagination[this.currentTab] = { currentPage: 0, pageSize: 10 };
            this.getTransactionHistory(this.currentTab);
          } else {
            this.pagination[this.currentTab] = { currentPage: 1, pageSize: 10 };
            this.getTransactionHistoryPaging(this.currentTab);
          }
        }
      }
    },
    currentTab: {
      deep: true,
      handler(newVal, oldVal) {
        const isMobile = window.innerWidth <= this.getMobileThresshold();
        if (newVal && newVal !== oldVal) {
          this.isLoadEnd[this.currentTab] = false;
          this.ticketList[this.currentTab] = [];
          if (isMobile) {
            this.pagination[this.currentTab] = { currentPage: 0, pageSize: 10 };

            this.getTransactionHistory(this.currentTab);
          } else {
            this.pagination[this.currentTab] = { currentPage: 1, pageSize: 10 };
            this.getTransactionHistoryPaging(this.currentTab);
          }
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const isMobile = window.innerWidth <= this.getMobileThresshold();
    const listCheck = [
      "/tai-khoan/lich-su-dat-mua/",
      "/tai-khoan/lich-su-dat-mua-chung/",
      "/tai-khoan/ve-da-tang/",
      "/tai-khoan/ve-duoc-tang/"
    ];

    if (listCheck.includes(to?.meta?.redirectBack) && !isMobile) {
      this.$store.commit("setSavePagination", this.pagination);
    }

    if (listCheck.includes(to?.meta?.redirectBack)) {
      this.$store.commit("setSaveFilterListActived", this.filterListActived);
    }

    if (to?.meta?.redirectBack === "/tai-khoan/ve-duoc-tang/") {
      this.$store.commit("setSaveFilterListActivedGiftTicket", this.filterListActivedGiftTicket);
    }

    next();
  },
  beforeRouteEnter(to, from, next) {
    const listCheck = [
      "/tai-khoan/lich-su-dat-mua/",
      "/tai-khoan/lich-su-dat-mua-chung/",
      "/tai-khoan/ve-da-tang/",
      "/tai-khoan/ve-duoc-tang/"
    ];

    if (!listCheck.includes(from?.meta?.redirectBack)) {
      next((vm) => {
        vm.setTab(1);
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="scss">
.ticketFavoriteTab {
  .nav {
    justify-content: start;
    .nav-item {
      flex: unset;
      .nav-link {
        padding: 8px 20px 5px;
        color: #666666;
        font-weight: 600;
        &.active {
          color: #0d234a;
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }
  .ticketFavoriteTabNone {
    text-align: center;
    padding-top: 19px;
    p {
      color: #666666;
      font-size: 15px;
      margin: 11px 0 10px 0;
    }
  }
}

.filter {
  display: flex;
  .mDropdown {
    white-space: nowrap;
    min-width: 165px;
  }
  .filterGift {
    min-width: 230px;
  }
  .btnChooseMoney {
    font-weight: 400 !important;
    padding-left: 15px;
    &:after {
      right: 10px;
    }
  }

  .boxItemNotify {
    p {
      font-weight: 400 !important;
    }
  }
}

.content-list {
  min-height: 400px;

  &__item {
    border-radius: 3px;
    background-color: #ffffff;
    margin-bottom: 30px;
    cursor: pointer;

    .content-block {
      background-color: transparent !important;
    }
    &:hover {
      background-color: #f8f9fa;
    }
    &:active {
      background-color: #dae0e5;
    }
  }
}

.containerMessage {
  padding-left: 20px;
  padding-right: 20px;
  .status-icon {
    margin-right: 10px;
    margin-top: -2px;
  }
  .message {
    display: flex;
    margin-bottom: 10px;
  }
}
strong {
  font-weight: 600;
}
@media (max-width: map-get($grid-breakpoints, "lg")) {
  .ticketFavoriteTab {
    .nav-fill {
      overflow: auto !important;
      flex-wrap: nowrap !important;
      display: flex;
      .nav-item {
        width: 100%;
        .nav-link {
          color: #666666;
          font-weight: 600;
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 5px !important;
          margin: 0 !important;
        }
        .active {
          color: #0d234a;
          border-bottom: 2px solid #0d234a;
          text-align: center;
        }
      }
    }
  }

  .filter {
    width: 100%;
    flex-wrap: wrap;
    .mDropdown {
      width: 100%;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
  .containerMessage {
    padding-left: 10px;
    padding-right: 10px;
  }

  .content-block .content-detail .order-detail {
    min-width: 50%;
    padding-right: 0px;
  }
}
</style>
