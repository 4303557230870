/**
 * Return two digits for datetime format
 *
 * @param   {[String, Number]}  d   Digit needs to be formated
 *
 * @return  {String}                Digit formated
 */
 function twoDigits(d) {
    if (0 <= d && d < 10) return "0" + d.toString();
    if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
    return d.toString();
  }
  
  const DateUtils = {
    /**
     * Format date from Date object
     *
     * @param   {Date}  date    Date Object
     *
     * @return  {String}        Example: "2020-08-13"
     */
    formatDate: function (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      return [year, month, day].join("-");
    },
    /**
     * Get DateString of current time with timezone
     *
     * @param   {String}  timezone  Timezone
     *
     * @return  {String}  Date String of current time with timezone
     */
    today: function (timezone) {
      try {
        const nDate = new Date().toLocaleString("en-US", {
          timeZone: timezone,
        });
  
        return nDate;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    toTransaction: function () {
      return new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[^0-9]/g, "");
    },
    /**
     * Return date string with SQL format in UTC timezone
     *
     * @return  {String}  UTC time with SQL format. Ex: "2020-08-13 10:00:00"
     */
    toMySQLFormat: function (date) {
      return (
        date.getUTCFullYear() +
        "-" +
        twoDigits(1 + date.getUTCMonth()) +
        "-" +
        twoDigits(date.getUTCDate()) +
        " " +
        twoDigits(date.getUTCHours()) +
        ":" +
        twoDigits(date.getUTCMinutes()) +
        ":" +
        twoDigits(date.getUTCSeconds())
      );
    },
    /**
     * Convert String to Date object
     *
     * @return  {Date}  Date object
     */
    strToDate: function (dateStr) {
      return new Date(dateStr);
    },
    /**
     * Convert UTC date to GMT date
     *
     * @return  {Date}  GMT Date
     */
    utcToGmt: function (utcDate, timezone = 7) {
      let gmtDate = utcDate.setHours(utcDate.getHours() + timezone);
      return new Date(gmtDate);
    },
    /**
     * Format Datetime in SQL format
     *
     * @param   {Date}  date  Date to be formated
     *
     * @return  {String}        Formated Datetime. Ex: "2020-08-13 10:00:00"
     */
    formatDateTime(date) {
      let year = date.getFullYear();
      let month = twoDigits(date.getMonth() + 1);
      let day = twoDigits(date.getDate());
      let hour = twoDigits(date.getHours());
      let minute = twoDigits(date.getMinutes());
      let second = twoDigits(date.getSeconds());
      return `${[year, month, day].join("-")} ${[hour, minute, second].join(
        ":"
      )}`;
    },
  
    /**
     * Convert date string to cron expression
     *
     * @param   {String}  dateStr  date string
     *
     * @return  {String}           cron expression
     */
    dateStrToCron(dateStr) {
      let date = new Date(dateStr);
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      return `${[second, minute, hour].join(" ")} * * *`;
    },
  
    toTelcoFormat(date) {
      date = new Date(date.setHours(date.getHours() + 7));
      let year = date.getFullYear();
      let month = twoDigits(date.getMonth() + 1);
      let day = twoDigits(date.getDate());
      let hour = twoDigits(date.getHours());
      let minute = twoDigits(date.getMinutes());
      let second = twoDigits(date.getSeconds());
      return [year, month, day, hour, minute, second].join("");
    },
  };
  module.exports = DateUtils;
  